import Keycloak from 'keycloak-js';
import { createStore } from '../utils/createStore';

export { apiStore } from './api';

export type State = {
  isAuthorized?: boolean;
  user: {
    email?: string;
    username?: string;
  } | null;
};

type Actions = {
  setAuthorization: (keycloak: Keycloak) => void;
};

const initialState: State = {
  isAuthorized: false,
  user: null,
};

export const useStore = createStore<State, Actions>(initialState, (set) => ({
  setAuthorization: ({ authenticated, tokenParsed }: Keycloak) =>
    set((draft) => {
      draft.isAuthorized = authenticated;
      draft.user = {
        email: tokenParsed?.email,
        username: tokenParsed?.preferred_username,
      };
      return draft;
    }),
}));
