import { StepProps, StepConfig, StepSection } from 'app/components/networks/types';
import { getStepSummaryConfig } from 'app/components/networks/constants';
import { Step, StepId, baseSteps } from 'app/pages/networks/create/steps';
import { StepSummary } from './step-summary';

export const SummaryStep = ({ form: { values } }: StepProps) => {
  const stepsConfig: StepConfig = getStepSummaryConfig(values);

  return (
    <>
      {baseSteps.map(
        ({ id, label }: Step, index: number) =>
          id !== StepId.SUMMARY && (
            <StepSummary
              key={id}
              index={index}
              stepId={id}
              stepLabel={label}
              sections={stepsConfig[id] as StepSection[]}
            />
          ),
      )}
    </>
  );
};
