import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { ErrorType } from 'data';

export type ApiStore = {
  error?: { type: ErrorType; payload?: string };
};

export const initialApiState = {};

export const apiStore = create(subscribeWithSelector<ApiStore>(() => initialApiState));
