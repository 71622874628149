/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AddressVotingRequestDto = {
  nodeName?: string;
  address?: string;
  vote?: AddressVotingRequestDto.vote;
};
export namespace AddressVotingRequestDto {
  export enum vote {
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT',
  }
}
