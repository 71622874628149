/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlethioParamsDto } from './AlethioParamsDto';
import type { GenesisConfigRequestDto } from './GenesisConfigRequestDto';
import type { NumOfNodesDto } from './NumOfNodesDto';
import type { ResourcesDto } from './ResourcesDto';
export type NetworkRequestDTO = {
  name: string;
  numOfNodesDto: NumOfNodesDto;
  validatorResources: ResourcesDto;
  bootNodeResources: ResourcesDto;
  rpcNodeResources: ResourcesDto;
  consensusMechanism: NetworkRequestDTO.consensusMechanism;
  genesisConfigDto: GenesisConfigRequestDto;
  rpcOpenIDConfigurationURL?: string | null;
  rpcAuthStrategy: NetworkRequestDTO.rpcAuthStrategy;
  besuImage?: string;
  besuImageSecret?: string | null;
  enableBlockExplorer: boolean;
  alethioParamsDto?: AlethioParamsDto;
};
export namespace NetworkRequestDTO {
  export enum consensusMechanism {
    QBFT = 'QBFT',
    IBFT2 = 'IBFT2',
    CLIQUE = 'CLIQUE',
  }
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
