export const handleFloatNumberInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\d|\./.test(keyValue)) return;
  e.preventDefault();
};

export const handleIntegerInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\d/.test(keyValue)) return;
  e.preventDefault();
};
