import * as Yup from 'yup';
import { minNodesNum } from 'app/constants';
import { NetworkRequestDTO } from 'data';
import {
  INTEGER_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  getMinValueErrorMessage,
  resourcesSchema,
} from 'data/utils/validation';
import { NodeResources } from '../network-setup/NetworkSetupStepSchema';

export type ResourcesStepSchema = Pick<NetworkRequestDTO, 'numOfNodesDto'> & {
  validatorResources: NodeResources;
  bootNodeResources: NodeResources;
  rpcNodeResources: NodeResources;
};

const numOfNodesSchema = (field: string) =>
  Yup.number()
    .required(REQUIRED_ERROR_MESSAGE)
    .integer(INTEGER_ERROR_MESSAGE)
    .test('Minimum value', (value, context) => {
      const consensus = context?.from?.[1]?.value.consensusMechanism;
      const minValue = minNodesNum[consensus]?.[field];

      return Number(value) >= +minValue || context.createError({ message: getMinValueErrorMessage(minValue) });
    });

export const ResourcesSchemaValidation: Yup.Schema<ResourcesStepSchema> = Yup.object({
  numOfNodesDto: Yup.object({
    validatorsNum: numOfNodesSchema('validatorsNum'),
    bootnodesNum: numOfNodesSchema('bootnodesNum'),
    rpcNodesNum: numOfNodesSchema('rpcNodesNum'),
  }),
  validatorResources: resourcesSchema,
  bootNodeResources: resourcesSchema,
  rpcNodeResources: resourcesSchema,
});
