import { createContext } from 'react';
import { StepId } from '../steps';

export type ServerErrors = {
  [key in StepId]?: { [key: string]: string | object };
};

export type TNetworkStepperContext = {
  serverErrors: ServerErrors;
  setServerErrors: (errors: ServerErrors) => void;
  hasStepError: (id: StepId) => boolean;
};

export const NetworkStepperContext = createContext<TNetworkStepperContext>({
  serverErrors: {} as ServerErrors,
  setServerErrors: () => {},
  hasStepError: () => false,
});
