import React, { memo } from 'react';
import cx from 'classnames';

import s from './styles.module.scss';

interface IAvatar {
  src?: string;
  name?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Avatar = memo(({ src, name = 'Name', className, ...props }: IAvatar) => (
  <button type="button" className={s.avatar} {...props}>
    <div
      className={cx(s.avatarImage, className)}
      style={
        src
          ? {
              backgroundImage: `url(${src})`,
            }
          : {}
      }
    >
      {!src && <div className={s.avatarImageFallback}>{`${name.charAt(0).toUpperCase()}`}</div>}
    </div>
  </button>
));

Avatar.displayName = 'Avatar';

export * from './menu';
export { Avatar };
