import cx from 'classnames';
import { Text } from '@intellecteu/common-ui';

import s from './styles.module.scss';

interface IFormControl {
  className?: string;
  component: any;
  disabled?: boolean;
  id: string;
  error?: string;
  [key: string]: any;
}

const FormControl = ({ className, component, error, disabled, ...props }: IFormControl): JSX.Element => {
  const Input = component;

  return (
    <div className={cx(s.formControl, className)}>
      <Input disabled={disabled} {...props} error={error} />

      {error && (
        <Text color="error" size="xxs" className={s.error}>
          {error}
        </Text>
      )}
    </div>
  );
};

export default FormControl;
