import * as Yup from 'yup';
import * as steps from 'app/components/networks/create/steps';

export enum StepId {
  NETWORK_SETUP = 'networkSetup',
  RESOURCES = 'resources',
  GENESIS_BLOCK = 'genesisBlock',
  SUMMARY = 'summary',
}

export type Step = {
  id: StepId;
  label: string;
  component: React.ElementType;
  validationSchema?: Yup.Schema<
    steps.NetworkSetupStepSchema | steps.GenesisBlockStepSchema | steps.ResourcesStepSchema
  >;
};

/** Considered the maximum possible steps for a user -- optionally perform filtering logic in `generateSteps` if you need to */
export const baseSteps: Step[] = [
  {
    id: StepId.NETWORK_SETUP,
    label: 'Configurations',
    component: steps.NetworkSetupStep,
    validationSchema: steps.NetworkSetupSchemaValidation,
  },
  {
    id: StepId.GENESIS_BLOCK,
    label: 'Configure genesis block',
    component: steps.GenesisBlockStep,
    validationSchema: steps.GenesisBlockSchemaValidation,
  },
  {
    id: StepId.RESOURCES,
    label: 'Resources',
    component: steps.ResourcesStep,
    validationSchema: steps.ResourcesSchemaValidation,
  },
  {
    id: StepId.SUMMARY,
    label: 'Summary',
    component: steps.SummaryStep,
  },
];

export const generateSteps = (): Step[] => {
  // Depending on the type of user, and the requirements for the form that are needed -- you can perform filtering logic here to omit steps that aren't
  // necessary for each case
  return baseSteps;
};

export const getStepSchema = (currentIndex: number, stepsArr: Step[]) => {
  return stepsArr[currentIndex].validationSchema;
};
