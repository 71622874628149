import { useEffect, useState } from 'react';
import {
  FileUploader,
  InfoItem,
  Radio,
  SelectInput,
  TextInput,
  onSelectChangeAdapter,
  selectValueAdapter,
} from '@intellecteu/common-ui';
import { handleIntegerInput } from 'app/utils';
import { Labels, minNodesNum } from 'app/constants';
import { Field } from 'app/components/core';
import { StepProps } from 'app/components/networks/types';
import { NetworkRequestDTO } from 'data';
import { consensusOptions, protocolsList } from '../../../constants';

import s from './styles.module.scss';

export const GenesisBlockStep = ({
  form: {
    values: { genesisFile, consensusMechanism },
    setFieldValue,
    setFieldError,
  },
}: StepProps) => {
  const uploadedFile = genesisFile?.[0];
  const [showForm, setShowForm] = useState<boolean>(!uploadedFile);

  const setNodesMinValues = (cons: NetworkRequestDTO.consensusMechanism) => {
    const { validatorsNum, bootnodesNum, rpcNodesNum } = minNodesNum[cons];

    setFieldValue('numOfNodesDto.validatorsNum', validatorsNum, false);
    setFieldValue('numOfNodesDto.bootnodesNum', bootnodesNum, false);
    setFieldValue('numOfNodesDto.rpcNodesNum', rpcNodesNum, false);
  };

  const clearConsensus = () => setFieldValue('consensusMechanism', '');
  const setConsensusError = () => setFieldError('genesisFile', 'Can not read consensus mechanism value from the file');

  const handleConsensusChange = (consensus: NetworkRequestDTO.consensusMechanism) => {
    if (consensus) {
      setNodesMinValues(consensus);
    } else if (genesisFile?.length) {
      setConsensusError();
    }
  };

  const onMethodSelect = (value: boolean) => {
    setShowForm(value);
    clearConsensus();
  };

  const onFileReaderLoad = (event: ProgressEvent<FileReader>) => {
    try {
      const parsedFile = JSON.parse(event.target?.result as string);
      const consFromFile = Object.keys(parsedFile.genesis.config)
        .map((k) => k?.toUpperCase())
        .find((v: NetworkRequestDTO.consensusMechanism) => protocolsList.includes(v));

      setFieldValue('consensusMechanism', consFromFile?.toUpperCase());
    } catch (e) {
      setConsensusError();
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = onFileReaderLoad;
      reader.readAsText(uploadedFile);
    }
  }, [genesisFile]);

  useEffect(() => handleConsensusChange(consensusMechanism), [consensusMechanism]);

  return (
    <div className={s.formSidebarContent}>
      <div className={s.inputMethodForm}>
        <InfoItem
          className={s.formSidebarSectionTitle}
          label={Labels.SELECT_INPUT_METHOD}
          labelSize="s"
          labelColor="dark"
          labelWeight="bold"
        />

        <Radio
          name="inputMethod"
          label={Labels.ENTER_MANUALLY}
          checked={showForm}
          onChange={() => onMethodSelect(true)}
        />

        <Radio
          name="inputMethod"
          label={Labels.UPDATE_CONFIGURATION_FILE}
          checked={!showForm}
          onChange={() => onMethodSelect(false)}
        />
      </div>

      {showForm ? (
        <>
          <Field
            options={consensusOptions}
            component={SelectInput}
            id="consensusMechanism"
            name="consensusMechanism"
            label={`${Labels.CONSENSUS}*`}
            onChangeAdapter={onSelectChangeAdapter}
            valueAdapter={selectValueAdapter(consensusOptions)}
          />

          <Field
            className={s.containerFormField}
            component={TextInput}
            id="genesisConfigDto.consensusAlgoBlockperiodseconds"
            name="genesisConfigDto.consensusAlgoBlockperiodseconds"
            label={`${Labels.BLOCK_PERIOD}*`}
            onKeyPress={handleIntegerInput}
            tooltipText="The minimum block time, in seconds"
          />
        </>
      ) : (
        <FileUploader name="genesisFile" accept=".json" onClearAllFn={clearConsensus} />
      )}
    </div>
  );
};

export { type GenesisBlockStepSchema, GenesisBlockSchemaValidation } from './GenesisBlockStepSchema';
