import { ErrorMessage } from 'formik';
import { Text } from '@intellecteu/common-ui';

interface CustomErrorMessageProps {
  name: string;
}

export const CustomErrorMessage = ({ name }: CustomErrorMessageProps) => (
  <ErrorMessage name={name}>
    {(error: string) => (
      <Text size="xxs" color={Text.color.error}>
        {error}
      </Text>
    )}
  </ErrorMessage>
);
