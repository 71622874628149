import { selectOptionsAdapter } from '@intellecteu/common-ui';
import { ResourcesForm } from 'app/components/core';
import { capitalize } from 'app/utils/helpers';
import { MemoryEnum, CpuCoresEnum, NodeRequestDTO, NetworkRequestDTO } from 'data';

export const cpuMin = window._env_?.NODE_CPU_MIN || import.meta.env.VITE_NODE_CPU_MIN;
export const memoryMin = window._env_?.NODE_RAM_MIN || import.meta.env.VITE_NODE_RAM_MIN;
export const storageFullMin = window._env_?.STORAGE_FULL_SYNC_MIN || import.meta.env.VITE_STORAGE_FULL_SYNC_MIN;
export const storageSnapMin = window._env_?.STORAGE_SNAP_SYNC_MIN || import.meta.env.VITE_STORAGE_SNAP_SYNC_MIN;

// QBFT nodes
export const qbftValidatorsNumMin = window._env_?.QBFT_VALIDATORS_MIN || import.meta.env.VITE_QBFT_VALIDATORS_MIN;
export const qbftBootNodesNumMin = window._env_?.QBFT_BOOTNODE_MIN || import.meta.env.VITE_QBFT_BOOTNODE_MIN;
export const qbftRpcNodesNumMin = window._env_?.QBFT_RPCNODE_MIN || import.meta.env.VITE_QBFT_RPCNODE_MIN;

// IBFT2 nodes
const ibftValidatorsNumMin = window._env_?.IBFT2_VALIDATORS_MIN || import.meta.env.VITE_IBFT2_VALIDATORS_MIN;
const ibftBootNodesNumMin = window._env_?.IBFT2_BOOTNODE_MIN || import.meta.env.VITE_IBFT2_BOOTNODE_MIN;
const ibftRpcNodesNumMin = window._env_?.IBFT2_RPCNODE_MIN || import.meta.env.VITE_IBFT2_RPCNODE_MIN;

// CLIQUE nodes
const cliqueValidatorsNumMin = window._env_?.CLIQUE_VALIDATORS_MIN || import.meta.env.VITE_CLIQUE_VALIDATORS_MIN;
const cliqueBootNodesNumMin = window._env_?.CLIQUE_BOOTNODE_MIN || import.meta.env.VITE_CLIQUE_BOOTNODE_MIN;
const cliqueRpcNodesNumMin = window._env_?.CLIQUE_RPCNODE_MIN || import.meta.env.VITE_CLIQUE_RPCNODE_MIN;

export const minNodesNum = {
  [NetworkRequestDTO.consensusMechanism.QBFT]: {
    validatorsNum: qbftValidatorsNumMin,
    bootnodesNum: qbftBootNodesNumMin,
    rpcNodesNum: qbftRpcNodesNumMin,
  },
  [NetworkRequestDTO.consensusMechanism.IBFT2]: {
    validatorsNum: ibftValidatorsNumMin,
    bootnodesNum: ibftBootNodesNumMin,
    rpcNodesNum: ibftRpcNodesNumMin,
  },
  [NetworkRequestDTO.consensusMechanism.CLIQUE]: {
    validatorsNum: cliqueValidatorsNumMin,
    bootnodesNum: cliqueBootNodesNumMin,
    rpcNodesNum: cliqueRpcNodesNumMin,
  },
};

const cpuDefault = String(window._env_?.NODE_CPU_RECOMMENDED || import.meta.env.VITE_NODE_CPU_RECOMMENDED);
const memoryDefault = String(window._env_?.NODE_RAM_RECOMMENDED || import.meta.env.VITE_NODE_RAM_RECOMMENDED);
const storageFullDefault = String(
  window._env_?.STORAGE_FULL_SYNC_RECOMMENDED || import.meta.env.VITE_STORAGE_FULL_SYNC_RECOMMENDED,
);
const storageSyncDefault = String(
  window._env_?.STORAGE_SNAP_SYNC_RECOMMENDED || import.meta.env.VITE_STORAGE_SNAP_SYNC_RECOMMENDED,
);

export const storageFullValue: UnitAdapter = {
  value: storageFullDefault,
  unit: MemoryEnum.Gi,
};
export const storageSnapValue: UnitAdapter = {
  value: storageSyncDefault,
  unit: MemoryEnum.Gi,
};

export const initialResources: ResourcesForm = {
  cpuRequested: { value: cpuDefault, unit: CpuCoresEnum.CPU },
  cpuLimit: { value: String(Number(cpuDefault) * 1.5), unit: CpuCoresEnum.CPU },
  memoryRequested: { value: memoryDefault, unit: MemoryEnum.Gi },
  memoryLimit: {
    value: String(Number(memoryDefault) * 1.5),
    unit: MemoryEnum.Gi,
  },
  storageRequested: storageSnapValue,
};

export const initialBootResources: ResourcesForm = {
  ...initialResources,
  storageRequested: storageFullValue,
};

export type UnitAdapter = {
  value: string;
  unit: MemoryEnum | keyof typeof MemoryEnum | CpuCoresEnum | keyof typeof CpuCoresEnum;
};

export const memoryUnitSize = {
  [MemoryEnum.Ei]: 2 ** 60,
  [MemoryEnum.Pi]: 2 ** 50,
  [MemoryEnum.Ti]: 2 ** 40,
  [MemoryEnum.Gi]: 2 ** 30,
  [MemoryEnum.Mi]: 2 ** 20,
  [MemoryEnum.Ki]: 2 ** 10,
};

export const memory = selectOptionsAdapter(Object.values(MemoryEnum));
export const cpu = Object.entries(CpuCoresEnum).map(([label, value]) => ({ value, label }));
export const nodeTypes = Object.entries(NodeRequestDTO.nodeType).map(([label, value]) => ({
  label: label !== NodeRequestDTO.nodeType.RPC ? capitalize(label.toLowerCase()) : label,
  value,
}));
