import { Status, type TOption } from '@intellecteu/common-ui';
import { TStatusConfig } from 'app/constants';
import { NodeResponseDTO } from 'data';

export const nodeStatusConfig: TStatusConfig = {
  [NodeResponseDTO.nodeStatus.INITIALIZING]: {
    mode: Status.mode.processing,
    label: 'Initializing',
  },
  [NodeResponseDTO.nodeStatus.INITSUCCEEDED]: {
    mode: Status.mode.success,
    label: 'Init succeeded',
  },
  [NodeResponseDTO.nodeStatus.INITFAILED]: {
    mode: Status.mode.error,
    label: 'Init failed',
  },
  [NodeResponseDTO.nodeStatus.RUNNING]: {
    mode: Status.mode.success,
    label: 'Running',
  },
  [NodeResponseDTO.nodeStatus.CRASHED]: {
    mode: Status.mode.error,
    label: 'Crashed',
  },
};

export const nodeStatusOptions: TOption[] = Object.keys(NodeResponseDTO.nodeStatus).map((key: string) => ({
  label: nodeStatusConfig[key].label,
  value: key,
}));
