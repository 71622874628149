import * as Yup from 'yup';
import { cpuMin, memoryMin, memoryUnitSize, storageFullMin, storageSnapMin } from 'app/constants/resources';
import { formatCpuValue, unitSplitter } from 'app/utils/helpers';
import { CpuMetricsEnum, MemoryEnum, NodeRequestDTO, RpcNodeGroupRequestDto } from 'data';

export const REQUIRED_ERROR_MESSAGE = 'Field is required';
export const INVALID_ERROR_MESSAGE = 'Invalid format';
export const INTEGER_ERROR_MESSAGE = 'Value should be integer';
export const INVALID_URL_ERROR_MESSAGE = 'Invalid url';
export const INVALID_IP_ADDRESS_ERROR_MESSAGE = 'Invalid IP address';
export const DEFAULT_NAME_VALIDATION_NOTE = `Name must consist of lower case alphanumeric characters or ' - ', and must start with a letter`;
export const NODE_NAME_VALIDATION_NOTE = `Name must consist of lower case alphanumeric characters or ' - ', and must start/end with an alphanumeric character`;
const IMAGE_SECRET_VALIDATION_NOTE = `Image secret must consist of lower case alphanumeric characters, ' . ' or ' - ', and must start/end with an alphanumeric character`;
const PASSWORD_VALIDATION_NOTE =
  'Password must contain at least 1 uppercase character, 1 lowercase character, 1 special character and 1 digit';

export const getMinValueErrorMessage = (min: number, unit?: string) => `Min value is ${min} ${unit ?? ''}`;
export const getMaxValueErrorMessage = (max: number, unit?: string) => `Max value is ${max} ${unit ?? ''}`;
export const getMinLengthErrorMessage = (entityName: string, min: number) =>
  `${entityName} cannot be less than ${min} characters`;
export const getMaxLengthErrorMessage = (entityName: string, max: number) =>
  `${entityName} cannot exceed ${max} characters`;

const defaultNameRegExp = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;
const nodeNameRegExp = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;
const besuImageRegExp =
  /^(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?.)*[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?::[1-9]\d*)?)?(?:(?:[a-z0-9]+(?:[._-]+))*[a-z0-9]+)*(?:[a-z0-9]+(?:[._-]+))*[a-z0-9]+(?::\w[\w.-]{0,127}|@[A-Za-z0-9_+.-]+:[A-Fa-f0-9]+)?$/;
const besuImageSecretRegExp = /^[a-z0-9](?:[a-z0-9.-]*[a-z0-9])?$/;
const ethereumAddressRegExp = /^0x[a-fA-F0-9]{40}$/;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
const noSpacesRegExp = /^\S*$/;
const ipAddressRegExp =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-cpu

const cpuResourcesRegExp = /^\d+\.(\d{1,3})$|^\d+$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-memory

const memoryResourcesRegExp = /^\d+\.\d+$|^\d+$/;

export const defaultNameSchema = Yup.string()
  .max(23, getMaxLengthErrorMessage('Name', 23))
  .matches(defaultNameRegExp, DEFAULT_NAME_VALIDATION_NOTE)
  .required(REQUIRED_ERROR_MESSAGE);

const nodeNameSchema = Yup.string()
  .max(23, getMaxLengthErrorMessage('Name', 23))
  .matches(nodeNameRegExp, NODE_NAME_VALIDATION_NOTE)
  .required(REQUIRED_ERROR_MESSAGE);

const memorySchemaFn = (msg: string) =>
  Yup.object({
    value: Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .matches(memoryResourcesRegExp, INVALID_ERROR_MESSAGE)
      .test('Minimum value', getMinValueErrorMessage(memoryMin, MemoryEnum.Gi), (value, context) => {
        const memorySize = parseFloat(value) * memoryUnitSize[context?.parent?.unit];

        return Number(memorySize) >= memoryMin * memoryUnitSize.Gi;
      })
      .test('memory validation for requested value', msg, function (_, context) {
        const limitSize =
          parseFloat(context?.from?.[1]?.value.memoryLimit?.value) *
          memoryUnitSize[context?.from?.[1]?.value.memoryLimit?.unit];
        const requestSize =
          parseFloat(context?.from?.[1]?.value.memoryRequested?.value) *
          memoryUnitSize[context?.from?.[1]?.value.memoryRequested?.unit];

        return limitSize >= requestSize;
      }),
  });

const cpuSchemaFn = (msg: string) =>
  Yup.object({
    value: Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .matches(cpuResourcesRegExp, INVALID_ERROR_MESSAGE)
      .test('Minimum value', getMinValueErrorMessage(cpuMin, CpuMetricsEnum.CPU), (value, context) => {
        const cpuSize = context?.parent?.unit ? unitSplitter(context?.parent) : value;
        const finalSize = formatCpuValue(cpuSize);

        return Number(finalSize) >= cpuMin * 1000;
      })
      .test('CPU validation for requested value', msg, function (_, context) {
        const value = context?.from?.[1].value;
        const cpuRequested = value.cpuRequested.unit ? unitSplitter(value.cpuRequested) : value.cpuRequested.value;
        const cpuLimit = value.cpuLimit.unit ? unitSplitter(value.cpuLimit) : value.cpuLimit.value;

        const requestSize = formatCpuValue(cpuRequested);
        const limitSize = formatCpuValue(cpuLimit);

        return requestSize <= limitSize;
      }),
  });

const storageSchemaFn = () =>
  Yup.object({
    value: Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .matches(memoryResourcesRegExp, INVALID_ERROR_MESSAGE)
      .test('Minimum value', (value, context) => {
        const nodeType = context?.from?.[2].value.nodeType;
        const storageMin = nodeType === NodeRequestDTO.nodeType.BOOT ? storageFullMin : storageSnapMin;
        const storageSize = parseFloat(value) * memoryUnitSize[context?.parent?.unit];

        return (
          Number(storageSize) >= storageMin * memoryUnitSize.Gi ||
          context.createError({ message: getMinValueErrorMessage(storageMin, MemoryEnum.Gi) })
        );
      }),
  });

export const resourcesSchema = Yup.object({
  cpuLimit: cpuSchemaFn('Value should be greater than or equal to requested CPU'),
  cpuRequested: cpuSchemaFn('Value should be less than or equal to CPU limit'),
  memoryRequested: memorySchemaFn('Value should be less than or equal to memory limit'),
  memoryLimit: memorySchemaFn('Value should be greater than or equal to requested memory'),
  storageRequested: storageSchemaFn(),
});

export const besuImageSchema = Yup.string()
  .required(REQUIRED_ERROR_MESSAGE)
  .min(4, 'Min length is 4')
  .max(255, getMaxLengthErrorMessage('Image', 255))
  .matches(besuImageRegExp, INVALID_ERROR_MESSAGE);

export const besuImageSecretSchema = Yup.string()
  .optional()
  .max(255, getMaxLengthErrorMessage('Image secret', 255))
  .matches(besuImageSecretRegExp, IMAGE_SECRET_VALIDATION_NOTE);

export const NodeCreateSchema = Yup.object({
  name: nodeNameSchema,
  networkName: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  nodeResources: resourcesSchema,
  besuImage: besuImageSchema,
  besuImageSecret: besuImageSecretSchema,
});

export const VotingCreateSchema = Yup.object({
  address: Yup.string().required(REQUIRED_ERROR_MESSAGE).matches(ethereumAddressRegExp, INVALID_ERROR_MESSAGE),
  vote: Yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const RpcUserCreateSchema = Yup.object({
  username: Yup.string()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(2, getMinLengthErrorMessage('Username', 2))
    .max(15, getMaxLengthErrorMessage('Username', 15))
    .matches(defaultNameRegExp, DEFAULT_NAME_VALIDATION_NOTE),
  password: Yup.string()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(8, getMinLengthErrorMessage('Password', 8))
    .max(1024, getMaxLengthErrorMessage('Password', 1024))
    .matches(passwordRegExp, PASSWORD_VALIDATION_NOTE)
    .matches(noSpacesRegExp, 'Password must not contain spaces'),
  permissions: Yup.object()
    .required(REQUIRED_ERROR_MESSAGE)
    .test(
      'Permission groups arrays are not empty',
      REQUIRED_ERROR_MESSAGE,
      (permissions) => Object.values(permissions).flat().length !== 0,
    ),
});

export const RpcUserEditSchema = Yup.object({
  ...RpcUserCreateSchema.fields,
  password: Yup.string()
    .min(8, getMinLengthErrorMessage('Password', 8))
    .max(1024, getMaxLengthErrorMessage('Password', 1024))
    .matches(passwordRegExp, PASSWORD_VALIDATION_NOTE)
    .matches(noSpacesRegExp, 'Password must not contain spaces'),
});

export const RpcGroupCreateSchema = Yup.object({
  rpcGroupName: defaultNameSchema,
  networkName: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  resourcesPerNode: resourcesSchema,
  besuImage: besuImageSchema,
  besuImageSecret: besuImageSecretSchema,
  maxNumOfNodes: Yup.number()
    .integer(INTEGER_ERROR_MESSAGE)
    .min(1, getMinValueErrorMessage(1))
    .max(20, getMaxValueErrorMessage(20))
    .required(REQUIRED_ERROR_MESSAGE),
  rpcOpenIDConfigurationURL: Yup.string()
    .url(INVALID_URL_ERROR_MESSAGE)
    .test('Required if auth strategy is OpenID', REQUIRED_ERROR_MESSAGE, (value, context) => {
      const isOpenIDAuth = context?.parent?.rpcAuthStrategy === RpcNodeGroupRequestDto.rpcAuthStrategy.OPENID;

      return !isOpenIDAuth || (isOpenIDAuth && !!value);
    }),
  hostsAllowList: Yup.array().of(
    Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .test(
        'Should be a valid IP address if not Any option is selected',
        INVALID_IP_ADDRESS_ERROR_MESSAGE,
        (value, context) => {
          const isAnyHostsAllowed =
            context?.parent?.filter((hostname) => hostname === '*')?.length === context?.parent?.length;

          return !isAnyHostsAllowed ? ipAddressRegExp.test(value) : true;
        },
      ),
  ),
  apiGroups: Yup.array().min(1, REQUIRED_ERROR_MESSAGE),
});
