import { UnitAdapter } from 'app/constants';
import { ResourcesForm } from 'app/components/core';
import { MemoryEnum } from 'data';

export const unitSplitter = (obj?: UnitAdapter): string => {
  if (!obj) return '';
  return `${obj.value}${obj.unit}`;
};

export const prepareResourcesToRequest = ({
  cpuLimit,
  cpuRequested,
  memoryLimit,
  memoryRequested,
  storageRequested,
}: ResourcesForm) => ({
  cpuLimit: unitSplitter(cpuLimit),
  cpuRequested: unitSplitter(cpuRequested),
  memoryLimit: unitSplitter(memoryLimit),
  memoryRequested: unitSplitter(memoryRequested),
  storageRequested: unitSplitter(storageRequested),
});

export const formatCpuValue = (value: string): number => {
  const milliCpuRegExp = /^[0-9.]+m$/;

  return milliCpuRegExp.test(value) ? +value.replace('m', '') : +value * 1000;
};

export const formatMemoryValue = (value: string): UnitAdapter => {
  const amount = value.match(/\d+/g) as RegExpMatchArray;
  const unitValue = value.match(/[a-zA-Z]+/g) as RegExpMatchArray;

  return {
    value: amount[0],
    unit: unitValue[0] as MemoryEnum,
  };
};
