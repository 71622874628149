import { useEffect } from 'react';
import {
  SelectInput,
  Text,
  TextInput,
  Toggle,
  selectValueAdapter,
  onSelectChangeAdapter,
} from '@intellecteu/common-ui';
import { Labels } from 'app/constants';
import { Field } from 'app/components/core';
import { authTypesOptions, testRpcConfigUrl } from 'app/components/networks/constants';
import { StepProps } from 'app/components/networks/types';
import { NetworkRequestDTO } from 'data';
import { DEFAULT_NAME_VALIDATION_NOTE } from 'data/utils/validation';

import s from './styles.module.scss';

export const NetworkSetupStep = ({ form: { values, setFieldValue } }: StepProps) => {
  const { rpcAuthStrategy, rpcOpenIDConfigurationURL: rpcConfigUrl } = values;
  const isOpenIDAuth = rpcAuthStrategy === NetworkRequestDTO.rpcAuthStrategy.OPENID;
  const isCredentialsAuth = rpcAuthStrategy === NetworkRequestDTO.rpcAuthStrategy.CREDENTIALS;

  useEffect(() => {
    if (isOpenIDAuth) {
      setFieldValue('rpcOpenIDConfigurationURL', rpcConfigUrl || testRpcConfigUrl, false);
    } else {
      setFieldValue('rpcOpenIDConfigurationURL', '', false);
    }
  }, [rpcAuthStrategy]);

  return (
    <>
      <Field
        component={TextInput}
        id="name"
        name="name"
        label={`${Labels.NETWORK_NAME}*`}
        tooltipText={DEFAULT_NAME_VALIDATION_NOTE}
        data-1p-ignore
      />

      <Field component={TextInput} id="besuImage" name="besuImage" label={`${Labels.BESU_IMAGE}*`} />

      <Field component={TextInput} id="besuImageSecret" name="besuImageSecret" label={Labels.BESU_IMAGE_SECRET} />

      <div>
        <Field
          className={isCredentialsAuth && s.fieldWithoutMargin}
          options={authTypesOptions}
          component={SelectInput}
          id="rpcAuthStrategy"
          name="rpcAuthStrategy"
          label={`${Labels.RPC_AUTH_STRATEGY}*`}
          onChangeAdapter={onSelectChangeAdapter}
          valueAdapter={selectValueAdapter(authTypesOptions)}
        />

        {isCredentialsAuth && (
          <Text className={s.credentialsNote} size={Text.size.xxs}>
            You can create RPC users from the Network details page
          </Text>
        )}

        {isOpenIDAuth && (
          <Field
            component={TextInput}
            id="rpcOpenIDConfigurationURL"
            name="rpcOpenIDConfigurationURL"
            label={Labels.RPC_CONFIG_URL}
          />
        )}
      </div>

      <Toggle.Small label={Labels.GAS_FREE_NETWORK} checked disabled horizontal />
    </>
  );
};

export { type NetworkSetupStepSchema, NetworkSetupSchemaValidation } from './NetworkSetupStepSchema';
