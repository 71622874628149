/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourcesDto } from './ResourcesDto';
export type NodeRequestDTO = {
  name: string;
  networkName: string;
  nodeResources: ResourcesDto;
  nodeType: NodeRequestDTO.nodeType;
  besuImage?: string;
  besuImageSecret?: string | null;
};
export namespace NodeRequestDTO {
  export enum nodeType {
    VALIDATOR = 'VALIDATOR',
    BOOT = 'BOOT',
    RPC = 'RPC',
  }
}
