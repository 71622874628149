import { memo } from 'react';
import { Text } from '@intellecteu/common-ui';
import { CaptionSkeleton } from 'ui';

interface IFormCaption {
  isDataLoaded: boolean;
  data: Array<unknown> | undefined;
  text: string;
  isTotalView?: boolean;
}

export const PageSubCaption = memo(({ isDataLoaded, data, text, isTotalView }: IFormCaption): JSX.Element => {
  let suffix;
  let content;
  if (isDataLoaded && data) {
    suffix = data.length === 1 ? '' : 's';

    content = isTotalView ? `Total items: ${data.length}` : `${data.length} ${text}${suffix}`;
  } else {
    content = <CaptionSkeleton />;
  }
  return (
    <Text size={Text.size.xxs} color={Text.color.darkGrey}>
      {content}
    </Text>
  );
});

PageSubCaption.displayName = 'PageSubCaption';
