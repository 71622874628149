import * as Yup from 'yup';
import { UnitAdapter } from 'app/constants';
import { NetworkRequestDTO } from 'data';
import {
  REQUIRED_ERROR_MESSAGE,
  besuImageSchema,
  besuImageSecretSchema,
  defaultNameSchema,
} from 'data/utils/validation';

type NodeResource = Omit<UnitAdapter, 'unit'>;

export interface NodeResources {
  cpuLimit: NodeResource;
  memoryLimit: NodeResource;
  cpuRequested: NodeResource;
  memoryRequested: NodeResource;
  storageRequested: NodeResource;
}

export type NetworkSetupStepSchema = Pick<
  NetworkRequestDTO,
  'name' | 'rpcOpenIDConfigurationURL' | 'besuImage' | 'besuImageSecret'
>;

export const NetworkSetupSchemaValidation: Yup.Schema<NetworkSetupStepSchema> = Yup.object({
  name: defaultNameSchema,
  rpcOpenIDConfigurationURL: Yup.string()
    .url('Invalid url')
    .test('Required if auth strategy is OpenID', REQUIRED_ERROR_MESSAGE, (value, context) => {
      const isOpenIDAuth = context?.parent?.rpcAuthStrategy === NetworkRequestDTO.rpcAuthStrategy.OPENID;

      return !isOpenIDAuth || (isOpenIDAuth && !!value);
    }),
  besuImage: besuImageSchema,
  besuImageSecret: besuImageSecretSchema,
});
