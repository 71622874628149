/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsDto } from './PermissionsDto';
import type { ResourcesDto } from './ResourcesDto';
export type RpcNodeGroupRequestDto = {
  rpcGroupName?: string;
  besuImage?: string;
  besuImageSecret?: string | null;
  hostsAllowList?: Array<string>;
  networkName?: string;
  rpcAuthStrategy: RpcNodeGroupRequestDto.rpcAuthStrategy;
  maxNumOfNodes?: number;
  resourcesPerNode: ResourcesDto;
  permissions: Array<PermissionsDto>;
  apiGroups: Array<string>;
  rpcOpenIDConfigurationURL?: string;
};
export namespace RpcNodeGroupRequestDto {
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
