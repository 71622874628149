export enum ErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
  UNPROCESSABLE = 'UNPROCESSABLE',
}

export enum MemoryEnum {
  'Ei' = 'Ei',
  'Pi' = 'Pi',
  'Ti' = 'Ti',
  'Gi' = 'Gi',
  'Mi' = 'Mi',
  'Ki' = 'Ki',
}

export enum CpuCoresEnum {
  'CPU' = '',
  'milliCPU' = 'm',
}

export enum CpuMetricsEnum {
  'CPU' = 'CPU',
  'milliCPU' = 'milliCPU',
}

export enum QueryKeys {
  NETWORKS = 'NETWORKS',
  NODES = 'NODES',
  GENESIS_BLOCK = 'GENESIS_BLOCK',
  VOTINGS = 'VOTINGS',
  VALIDATORS_POOL = 'VALIDATORS_POOL',
  RPC_USERS = 'RPC_USERS',
  RPC_NODE_GROUPS = 'RPC_NODE_GROUPS',
}
