import { Status, type TOption, selectOptionsAdapter } from '@intellecteu/common-ui';
import { Labels, TStatusConfig } from 'app/constants';
import { unitSplitter } from 'app/utils/helpers';
import { InitNetworkDto } from 'app/pages/networks/create';
import { StepId } from 'app/pages/networks/create/steps';
import { NetworkNodeTypeStats, NetworkRequestDTO, NetworkResponseDTO, NodeRequestDTO, NodeResponseDTO } from 'data';
import type { StepConfig } from './types';

export const testRpcConfigUrl = window._env_?.TEST_RPC_OPENID_CONF_URL || import.meta.env.VITE_TEST_RPC_OPENID_CONF_URL;

export const authTypesOptions: TOption[] = [
  {
    label: 'Disabled',
    value: NetworkRequestDTO.rpcAuthStrategy.NONE,
  },
  {
    label: 'OpenID',
    value: NetworkRequestDTO.rpcAuthStrategy.OPENID,
  },
  {
    label: 'Credentials',
    value: NetworkRequestDTO.rpcAuthStrategy.CREDENTIALS,
  },
];

export const protocolsList: string[] = Object.values(NetworkRequestDTO.consensusMechanism);
export const consensusOptions: TOption[] = selectOptionsAdapter(protocolsList);

export const networkStatusConfig: TStatusConfig = {
  [NetworkResponseDTO.state.INITIALIZING]: {
    mode: Status.mode.processing,
    label: 'Initializing',
  },
  [NetworkResponseDTO.state.INITSUCCEEDED]: {
    mode: Status.mode.success,
    label: 'Init succeeded',
  },
  [NetworkResponseDTO.state.INITFAILED]: {
    mode: Status.mode.error,
    label: 'Init failed',
  },
  [NetworkResponseDTO.state.QUORUMALIVE]: {
    mode: Status.mode.success,
    label: 'Quorum alive',
  },
  [NetworkResponseDTO.state.QUORUMFAILED]: {
    mode: Status.mode.error,
    label: 'Quorum failed',
  },
};

export const getNodeStatistics = (nodeTypeStats?: Record<NodeRequestDTO.nodeType, NetworkNodeTypeStats>) => {
  const getAvailableNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats?.[nodeType]?.available;
  const getTotalNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats?.[nodeType]?.total;

  const availableValidators = getAvailableNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const availableBootnodes = getAvailableNodes(NodeResponseDTO.nodeType.BOOT);
  const availableRPCNodes = getAvailableNodes(NodeResponseDTO.nodeType.RPC);

  const totalValidators = getTotalNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const totalBootnodes = getTotalNodes(NodeResponseDTO.nodeType.BOOT);
  const totalRPCNodes = getTotalNodes(NodeResponseDTO.nodeType.RPC);

  const hasValidatorStats = availableValidators != null && totalValidators != null;
  const hasBootStats = availableBootnodes != null && totalBootnodes != null;
  const hasRPCStats = availableRPCNodes != null && totalRPCNodes != null;

  return {
    availableValidators,
    availableBootnodes,
    availableRPCNodes,
    totalValidators,
    totalBootnodes,
    totalRPCNodes,
    hasValidatorStats,
    hasBootStats,
    hasRPCStats,
  };
};

export const getStepSummaryConfig = (values: InitNetworkDto): StepConfig => {
  const {
    name,
    besuImage,
    besuImageSecret,
    consensusMechanism,
    numOfNodesDto: { validatorsNum, bootnodesNum, rpcNodesNum },
    rpcAuthStrategy,
    rpcOpenIDConfigurationURL,
    validatorResources,
    bootNodeResources,
    rpcNodeResources,
    genesisConfigDto: { consensusAlgoBlockperiodseconds },
    genesisFile,
  } = values;
  const genesisStepConfig = genesisFile?.length
    ? [
        {
          fields: [
            {
              label: Labels.CONSENSUS,
              value: consensusMechanism,
              name: 'consensusMechanism',
            },
            {
              label: Labels.GENESIS_FILE,
              value: genesisFile[0].name,
              name: 'genesisFile',
            },
          ],
        },
      ]
    : [
        {
          fields: [
            {
              label: Labels.CONSENSUS,
              value: consensusMechanism,
              name: 'consensusMechanism',
            },
            {
              label: Labels.BLOCK_PERIOD,
              value: String(consensusAlgoBlockperiodseconds),
              name: 'genesisConfigDto.consensusAlgoBlockperiodseconds',
            },
          ],
        },
      ];

  return {
    [StepId.NETWORK_SETUP]: [
      {
        fields: [
          {
            label: Labels.NETWORK_NAME,
            value: name,
            name: 'name',
          },
          {
            label: Labels.BESU_IMAGE,
            value: besuImage,
            name: 'besuImage',
          },
          {
            label: Labels.BESU_IMAGE_SECRET,
            value: besuImageSecret,
            name: 'besuImageSecret',
          },
          {
            label: Labels.RPC_AUTH_STRATEGY,
            value: authTypesOptions.find((type) => type.value === rpcAuthStrategy)?.label as string,
            name: 'rpcAuthStrategy',
          },
          {
            label: Labels.RPC_CONFIG_URL,
            value: rpcOpenIDConfigurationURL,
            name: 'rpcOpenIDConfigurationURL',
          },
        ],
      },
    ],
    [StepId.GENESIS_BLOCK]: genesisStepConfig,
    [StepId.RESOURCES]: [
      {
        title: 'Validator resources',
        fields: [
          {
            label: Labels.VALIDATORS_NUM,
            value: String(validatorsNum),
            name: 'numOfNodesDto.validatorsNum',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(validatorResources.cpuRequested),
            name: 'validatorResources.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(validatorResources.cpuLimit),
            name: 'validatorResources.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(validatorResources.memoryRequested),
            name: 'validatorResources.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(validatorResources.memoryLimit),
            name: 'validatorResources.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(validatorResources.storageRequested),
            name: 'validatorResources.storageRequested',
          },
        ],
      },
      {
        title: 'Bootnode resources',
        fields: [
          {
            label: Labels.BOOTNODES_NUM,
            value: String(bootnodesNum),
            name: 'numOfNodesDto.bootnodesNum',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(bootNodeResources.cpuRequested),
            name: 'bootNodeResources.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(bootNodeResources.cpuLimit),
            name: 'bootNodeResources.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(bootNodeResources.memoryRequested),
            name: 'bootNodeResources.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(bootNodeResources.memoryLimit),
            name: 'bootNodeResources.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(bootNodeResources.storageRequested),
            name: 'bootNodeResources.storageRequested',
          },
        ],
      },
      {
        title: 'RPC node resources',
        fields: [
          {
            label: Labels.RPC_NODES_NUM,
            value: String(rpcNodesNum),
            name: 'numOfNodesDto.rpcNodesNum',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(rpcNodeResources.cpuRequested),
            name: 'rpcNodeResources.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(rpcNodeResources.cpuLimit),
            name: 'rpcNodeResources.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(rpcNodeResources.memoryRequested),
            name: 'rpcNodeResources.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(rpcNodeResources.memoryLimit),
            name: 'rpcNodeResources.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(rpcNodeResources.storageRequested),
            name: 'rpcNodeResources.storageRequested',
          },
        ],
      },
    ],
  };
};
