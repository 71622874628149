/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressVotesDto } from './AddressVotesDto';
import type { GenesisConfigRequestDto } from './GenesisConfigRequestDto';
import type { NetworkNodeTypeStats } from './NetworkNodeTypeStats';
import type { NumOfNodesDto } from './NumOfNodesDto';
import type { ResourcesDto } from './ResourcesDto';
export type NetworkResponseDTO = {
  id?: string;
  name: string;
  numOfNodesDto?: NumOfNodesDto;
  validatorResources?: ResourcesDto;
  bootNodeResources?: ResourcesDto;
  rpcNodeResources?: ResourcesDto;
  genesisConfigDto?: GenesisConfigRequestDto;
  rpcURL?: string;
  createdBy?: string;
  creationDate?: string;
  consensus?: string;
  state?: NetworkResponseDTO.state;
  nodeTypeStats?: Record<string, NetworkNodeTypeStats>;
  besuImage?: string;
  chainID?: string;
  rpcWSURL?: string;
  rpcOpenIDConfigurationURL?: string;
  rpcAuthStrategy?: NetworkResponseDTO.rpcAuthStrategy;
  addressVotes?: Array<AddressVotesDto>;
};
export namespace NetworkResponseDTO {
  export enum state {
    INITIALIZING = 'INITIALIZING',
    INITSUCCEEDED = 'INITSUCCEEDED',
    INITFAILED = 'INITFAILED',
    QUORUMFAILED = 'QUORUMFAILED',
    QUORUMALIVE = 'QUORUMALIVE',
  }
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
