/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsDto } from './PermissionsDto';
import type { ResourcesDto } from './ResourcesDto';
export type RpcNodeGroupResponseDto = {
  rpcGroupName: string;
  besuImage?: string;
  besuImageSecret?: string;
  hostsAllowList?: Array<string>;
  networkName?: string;
  rpcAuthStrategy?: RpcNodeGroupResponseDto.rpcAuthStrategy;
  maxNumOfNodes?: number;
  resourcesPerNode?: ResourcesDto;
  permissions?: Array<PermissionsDto>;
  rpcOpenIDConfigurationURL?: string;
};
export namespace RpcNodeGroupResponseDto {
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
