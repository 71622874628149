import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

const Layout = loadable(() => import('app/pages/layout'));

const App = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
};

export default App;
