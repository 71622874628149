import * as Yup from 'yup';
import { NetworkRequestDTO } from 'data';
import { REQUIRED_ERROR_MESSAGE, INTEGER_ERROR_MESSAGE } from 'data/utils/validation';

export type GenesisBlockStepSchema = Pick<NetworkRequestDTO, 'genesisConfigDto'> & {
  consensusMechanism: string;
};

export const GenesisBlockSchemaValidation: Yup.Schema<GenesisBlockStepSchema> = Yup.object({
  consensusMechanism: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  genesisConfigDto: Yup.object({
    consensusAlgoBlockperiodseconds: Yup.number().required(REQUIRED_ERROR_MESSAGE).integer(INTEGER_ERROR_MESSAGE),
    consensusAlgoEpochlength: Yup.number().required(REQUIRED_ERROR_MESSAGE).integer(INTEGER_ERROR_MESSAGE),
    consensusAlgoRequesttimeoutseconds: Yup.number().required(REQUIRED_ERROR_MESSAGE).integer(INTEGER_ERROR_MESSAGE),
  }),
});
